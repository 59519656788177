var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`content-container ${
    _vm.hasOpenedHubChat
      ? _vm.isChatWidgetOpened
        ? 'container-with-chat-opened'
        : 'container-with-chat-closed'
      : 'float-content-container'
  } elevation-6`},[_c('v-row',{staticClass:"pt-3 mt-0 mr-0 mb-2",staticStyle:{"min-width":"100%","position":"sticky","top":"0px","background-color":"white","z-index":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('back')}}},[_c('v-icon',{attrs:{"size":"30px"}},[_vm._v("ph-caret-left")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"size":"33px"}},[_vm._v("ph-arrows-in-simple")])],1)],1),_c('main',{staticClass:"main-content"},[_c('h2',{staticClass:"content-title"},[_vm._v(_vm._s(_vm.item.title))]),_c('p',{staticClass:"content-subtitle mt-2"},[_vm._v(" "+_vm._s(_vm.item.description)+" ")]),_c('v-card-text',{staticClass:"pa-0",staticStyle:{"font-size":"14px"}},[(_vm.item.steps && _vm.item.steps.length)?_c('div',_vm._l((_vm.item.steps),function(step,index){return _c(step.is,_vm._g(_vm._b({key:`step-${index}`,tag:"component",staticClass:"mt-4",staticStyle:{"overflow-wrap":"break-word"}},'component',step.bind,false),step.on),[_vm._v(" "+_vm._s(step.inner)+" "),_vm._l((step.elements),function(innerStep,jindex){return _c(innerStep.is,_vm._g(_vm._b({key:`step-${index}-${jindex}`,tag:"component",staticClass:"mt-4",staticStyle:{"overflow-wrap":"break-word"}},'component',innerStep.bind,false),innerStep.on),[_vm._v(" "+_vm._s(innerStep.inner)+" ")])})],2)}),1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }