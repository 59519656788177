<template>
  <div
    :class="`content-container ${
      hasOpenedHubChat
        ? isChatWidgetOpened
          ? 'container-with-chat-opened'
          : 'container-with-chat-closed'
        : 'float-content-container'
    } elevation-6`"
  >
    <v-row
      class="pt-3 mt-0 mr-0 mb-2"
      style="
        min-width: 100%;
        position: sticky;
        top: 0px;
        background-color: white;
        z-index: 1;
      "
    >
      <v-btn icon @click="$emit('back')">
        <v-icon size="30px">ph-caret-left</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn icon @click="$emit('close')">
        <v-icon size="33px">ph-arrows-in-simple</v-icon>
      </v-btn>
    </v-row>

    <main class="main-content">
      <h2 class="content-title">{{ item.title }}</h2>

      <p class="content-subtitle mt-2">
        {{ item.description }}
      </p>

      <v-card-text class="pa-0" style="font-size: 14px">
        <div v-if="item.steps && item.steps.length">
          <component
            v-for="(step, index) in item.steps"
            :key="`step-${index}`"
            :is="step.is"
            v-bind="step.bind"
            v-on="step.on"
            class="mt-4"
            style="overflow-wrap: break-word"
          >
            {{ step.inner }}
            <component
              v-for="(innerStep, jindex) in step.elements"
              :key="`step-${index}-${jindex}`"
              :is="innerStep.is"
              v-bind="innerStep.bind"
              v-on="innerStep.on"
              class="mt-4"
              style="overflow-wrap: break-word"
            >
              {{ innerStep.inner }}
            </component>
          </component>
        </div>
      </v-card-text>
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CustomDetailContent",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["hasOpenedHubChat", "isChatWidgetOpened"]),
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.content-container {
  display: flex;
  flex-direction: column;

  font-family: "Poppins", sans-serif;
  z-index: 9999;
  background-color: rgb(255, 255, 255);

  max-width: 430px;
  max-height: 80%;

  padding: 0 22px 0;
  text-align: start;
  border-radius: 14px;
  overflow: auto;
}

.float-content-container {
  position: fixed;
  bottom: 8%;
  right: 20px;
}

.container-with-chat-opened {
  position: fixed;
  bottom: 9%;
  right: 400px;
}

.container-with-chat-closed {
  position: fixed;
  bottom: 9%;
  right: 90px;
}

.content-container {
  scrollbar-color: #888 #f0f0f0;
  scrollbar-width: thin;
}

.main-content {
  padding: 13px 9px 22px 26px;
  font-family: "Poppins", sans-serif;
}

.content-title {
  font-size: 18px;
  font-weight: 700;
  color: black;
  font-family: "Poppins", sans-serif;
}

.content-subtitle {
  font-size: 14px;
  color: #807e7e;
  font-weight: 500;
  margin-bottom: 22px;
}

.content-container ol {
  padding-left: 16px;
  color: black;
}

.content-container li {
  color: black;
  font-size: 14px;
}

.elevation-6 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
    0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
</style>
