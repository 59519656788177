<template>
  <div>
    <v-btn
      color="#8165D1"
      :class="`updates-btn ${
        hasOpenedHubChat
          ? isChatWidgetOpened
            ? 'float-with-chat-opened'
            : 'float-with-chat-closed'
          : 'floating-button'
      }`"
      :min-width="!showUpdatesMenu ? '157px' : '0px'"
      height="38px"
      :style="`text-transform: none; font-size: 12px; padding: 0px ${
        !showUpdatesMenu ? 'border-radius: 100%; padding: 8px' : ''
      }`"
      @click="showUpdatesMenu = !showUpdatesMenu"
    >
      <div v-if="!showUpdatesMenu">
        <v-icon left style="transform: scaleX(-1)" class="ml-0"
          >ph-megaphone-simple</v-icon
        >
        {{ $t("common.updates") }}
        <v-icon
          v-if="!showUpdatesMenu"
          right
          @click.stop=""
          style="
            background-color: #947cd8;
            border-radius: 100%;
            padding: 13px;
            margin-right: 0px;
          "
          @click="closeFloatingGuide"
          >mdi-close</v-icon
        >
      </div>
      <v-icon v-else class="pa-2">ph-caret-down</v-icon>
    </v-btn>

    <div
      v-if="showUpdatesMenu && didntChooseGuide"
      :class="`guide-container ${
        hasOpenedHubChat
          ? isChatWidgetOpened
            ? 'next-to-opened-chat'
            : 'next-to-chat-closed'
          : 'float-guide'
      } elevation-6`"
    >
      <div class="mt-2 d-flex justify-end">
        <v-icon
          class="close"
          @click="showUpdatesMenu = false"
          size="33"
          color="white"
          >ph-arrows-in-simple</v-icon
        >
      </div>

      <v-card-title class="px-0">
        <p class="greetings" v-html="$t('guide.greetings')"></p>
      </v-card-title>

      <v-card
        class="mt-0"
        style="
          box-shadow: unset;
          border: 1px solid #c1c1c1;
          border-radius: 14px !important;
        "
      >
        <v-card-text max-heigth="354px">
          <v-text-field
            v-model="searchQuery"
            append-icon="ph-magnifying-glass"
            :label="$t('action.search')"
            flat
            dense
            hide-details
            solo
            background-color="#F2F2F2"
            class="search-guide"
            id="guide-search"
          >
            <template #append>
              <v-icon size="20">ph-magnifying-glass</v-icon>
            </template>
          </v-text-field>

          <v-list dense :width="355" :height="235" style="overflow: auto">
            <v-list-item
              v-for="(item, index) in filteredGuideContent"
              :key="index"
              @click="openDetails(index)"
              class="px-2"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-medium"
                  style="font-size: 12px"
                  >{{ item }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>

      <v-btn
        class="mt-5"
        outlined
        color="primary"
        @click="initSupportChat"
        style="
          border: 1px solid #c1c1c1;
          border-radius: 14px !important;
          text-transform: none;
          background-color: white;
        "
      >
        {{ $t("techDiagnosis.chatSupport") }}
        <v-spacer />
        <v-icon left size="24">ph-chat-teardrop-text</v-icon>
      </v-btn>
    </div>

    <CustomDetailContent
      @back="selectedItem = {}"
      @close="showUpdatesMenu = false"
      v-else-if="selectedItem.custom && showUpdatesMenu"
      :item="selectedItem"
    />

    <DetailContent
      @back="selectedItem = {}"
      @close="showUpdatesMenu = false"
      v-else-if="selectedItem.title && showUpdatesMenu"
      :item="selectedItem"
    />
  </div>
</template>

<script>
import DetailContent from "@/components/home/DetailContent.vue";
import CustomDetailContent from "@/components/home/CustomDetailContent.vue";
import { initFreshChatGlobal } from "@/helpers/services/utils";
import { guides } from "@/helpers/variables/guides";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "Updates",
  components: { DetailContent, CustomDetailContent },
  props: {
    initOpenGuide: { type: Boolean, default: false },
  },

  data() {
    return {
      searchQuery: "",
      guideTopicsDetails: Object.values(guides),
      showUpdatesMenu: false,
      selectedItem: {},
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "company",
      "hasConectaSupport",
      "conectaNuvemResellerToken",
      "companyFreshChatConfig",
      "hasOpenedHubChat",
      "isChatWidgetOpened",
    ]),

    guideTopics() {
      return this.guideTopicsDetails.map((detail) => detail.title);
    },

    didntChooseGuide() {
      return Object.keys(this.selectedItem).length === 0;
    },

    filteredGuideContent() {
      return this.guideTopics.filter((topic) =>
        topic.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },

  methods: {
    ...mapActions(["verifyHubspotToken"]),
    ...mapMutations([
      "setFloatingGuide",
      "setOpenGuideTip",
      "setOpenedChat",
      "setFloatingGuide",
    ]),

    closeFloatingGuide() {
      this.setFloatingGuide(false);
      this.setOpenGuideTip();

      setTimeout(() => {
        this.setOpenGuideTip(false);
      }, 8000);
    },

    openDetails(index) {
      this.selectedItem = this.guideTopicsDetails[index];
    },

    async initSupportChat() {
      if (this.conectaNuvemResellerToken === this.company.reseller_token) {
        initFreshChatGlobal(
          this.currentUser,
          this.companyFreshChatConfig,
          this.hasConectaSupport
        );

        return;
      }
      await this.verifyHubspotToken();
      this.setOpenedChat();
      this.setFloatingGuide(false);
    },
  },

  watch: {
    showUpdatesMenu(value) {
      if (value) {
        this.selectedItem = {};
      }
    },
  },

  beforeMount() {
    this.showUpdatesMenu = this.initOpenGuide;
  },
};
</script>

<style scoped>
.guide-container {
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  z-index: 9999;
  background: linear-gradient(180deg, #5b36c3 0%, #f8f8f8 100%);

  max-width: 430px;
  max-height: 80%;
  overflow: auto;
  border-radius: 14px;
  padding: 13px 26px 27px;
}

.float-guide {
  position: fixed;
  bottom: 8%;
  right: 20px;
}

.next-to-opened-chat {
  position: fixed;
  bottom: 9%;
  right: 400px;
}

.next-to-chat-closed {
  position: fixed;
  bottom: 9%;
  right: 90px;
}

.close {
  cursor: pointer;
  border-radius: 100%;
}

.greetings {
  max-width: 300px;
  color: white;
  font-weight: bold;
  margin-bottom: 4px;
}

.v-card-title {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.2;
}

.v-list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.updates-btn {
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  border-radius: 24px;
  font-family: "Poppins", sans-serif;
}

.floating-button {
  position: fixed;
  bottom: 2%;
  right: 20px;
}

.float-with-chat-opened {
  position: fixed;
  bottom: 3%;
  right: 400px;
}

.float-with-chat-closed {
  position: fixed;
  bottom: 3%;
  right: 90px;
}

.search-guide {
  border-radius: 12px;
}

::v-deep
  .search-guide
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input#guide-search {
  padding: 0px;
  font-size: 12px;
}

::v-deep
  .search-guide
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input#guide-search::content {
  color: #747474;
  font-size: 12px;
}

::v-deep
  .search-guide
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  label {
  color: #747474;
  font-size: 12px;
  display: block;
  text-align: center;
  line-height: 150%;
}

@media only screen and (max-width: 1440px) {
  .guide-container {
    max-width: 85%;
  }
}
</style>
